import { WcStack } from "@bpipartners/webui";
import React from "react";
import { BreadCrumbs, BreadCrumbsProps } from "@guberno/ui";
import { useTheme } from "@guberno/utils/theme";

interface BreadcrumbOnlyPageHeaderProps {
  height?: string | number;
  breadcrumbsProps?: BreadCrumbsProps;
  children?: React.ReactNode;
}

export const BreadcrumbOnlyPageHeader = ({
  height = undefined,
  breadcrumbsProps,
  children,
}: BreadcrumbOnlyPageHeaderProps) => {
  const theme = useTheme();

  return (
    <WcStack
      id="BreadcrumbOnlyPageHeader"
      width={"100%"}
      minWidth={theme.dimensions.minimumPageWidth}
      minHeight={theme.dimensions.breadCrumbHeight}
      marginBottom={16}
      bgcolor={theme.appColors.background}
      justifyContent={"center"}
    >
      {breadcrumbsProps && <BreadCrumbs {...breadcrumbsProps} />}
      {children && <>{children}</>}
    </WcStack>
  );
};

export default BreadcrumbOnlyPageHeader;

import React from "react";
import { RiLogoutBoxRLine } from "react-icons/ri";
import {
  MdOutlineAssignment,
  MdOutlineHome,
  MdOutlineAdminPanelSettings,
  MdFolderOpen,
  MdOutlineCalendarMonth,
  MdSupportAgent,
} from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { BiMessageAltDots } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { GrDocumentPerformance } from "react-icons/gr";

export const RenderDrawerIcons = (props: { itemType: any | unknown }) => {
  switch (props.itemType) {
    case "home":
      return <MdOutlineHome size={20} />;
    case "admin":
      return <MdOutlineAdminPanelSettings size={20} />;
    case "assessmentFramework":
      return <MdOutlineAssignment size={20} />;
    case "contentLibrary":
      return <MdFolderOpen size={20} />;
    case "selfAssessment":
      return <GrDocumentPerformance size={20} />;
    case "task":
      return <FaTasks size={20} />;
    case "planning":
      return <MdOutlineCalendarMonth size={20} />;
    case "userProfile":
      return <CgProfile size={20} />;
    case "logOut":
      return <RiLogoutBoxRLine size={20} />;
    case "messages":
      return <BiMessageAltDots size={20} />;
    case "support":
      return <MdSupportAgent size={20} />;
    default:
      return null;
  }
};

import { WcStack, WcTypography } from "@bpipartners/webui";
import React from "react";
import { useTheme } from "@guberno/utils/theme";

interface HeaderComponentProps {
  children: React.ReactNode;
  pageTitle: string;
  pageTitleTypographyProps?: React.ComponentProps<typeof WcTypography>;
  pageDescription: string;
  pageDescriptionTypographyProps?: React.ComponentProps<typeof WcTypography>;
  height?: string | number;
}

export const HeaderComponent = (props: HeaderComponentProps) => {
  const theme = useTheme();

  const {
    children,
    pageDescription,
    pageTitleTypographyProps = {
      variant: "h2",
      color: theme.appColors.primary,
    },
    pageDescriptionTypographyProps = {
      variant: "body1",
      color: theme.appColors.primary,
    },
    pageTitle,
    height = undefined,
  } = props;

  return (
    <WcStack height={height} id="HeaderComponent" spacing={2}>
      <WcStack
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
      >
        <WcStack width={"100%"}>
          <WcTypography {...pageTitleTypographyProps} suppressHydrationWarning>
            {pageTitle}
          </WcTypography>
        </WcStack>
        <WcStack width={"100%"}>{children}</WcStack>
      </WcStack>
      <WcStack direction="row" spacing={2}>
        <WcTypography {...pageDescriptionTypographyProps}>
          {pageDescription}
        </WcTypography>
      </WcStack>
    </WcStack>
  );
};

export default HeaderComponent;
